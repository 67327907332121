import React from 'react';
import { Button } from 'mpharma-components-library/lib/Button/Button';
import { withStyles } from '@material-ui/core';
import logo from '../../images/new_logo.png';
import { useMpharmaI18n, Text } from 'mpharma-i18n';

export const LogoutScreen = ({ onSignout, classes }) => {
  const { translateKey } = useMpharmaI18n();

  return (
    <div data-testid="logout-id" className={classes.container}>
      <div>
        <img src={logo} alt="mpharma_logo" />
        <div className={classes.logoutText}>
          <Text i18nKey="home.loggedUserOut">You've been logged out</Text>
        </div>
        <Button style={{ marginTop: 12 }} onClick={onSignout} secondary text={translateKey('home.signin')} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '80vh'
  },
  logoutText: {
    paddingTop: 10,
    color: 'rgba(0, 0, 0, 0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: 30
  }
};

export default withStyles(styles)(LogoutScreen);
