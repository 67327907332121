import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'mpharma-components-library/lib/Button';
import { Text } from 'mpharma-i18n';

export function BloomVersionDialog({ handleClose, classes, bloomVersions }) {
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.top}>
          <p className={classes.title}>
            <Text i18nKey="home.bloomModulesVersions">Bloom Modules Versions</Text>
          </p>
          <CloseIcon onClick={() => handleClose('openBloomVersionModal')} classes={{ root: classes.icon }} />
        </div>
        <div className={classes.body}>
          <p>
            <Text i18nKey="home.bloomModulesVersions.description">Versions</Text>
          </p>
          {bloomVersions && Object.keys(bloomVersions).length > 0 ? (
            <div className={classes.bodyMain}>
              <ul>
                {Object.keys(bloomVersions).map(key => (
                  <li key={key} data-testid={bloomVersions[key].key}>
                    <Text i18nKey={bloomVersions[key].key}>Module version</Text>:{' '}
                    <span>{bloomVersions[key].version}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className={classes.noBloomModule}>
              <p>
                <Text i18nKey="home.noBloomModule">No module loaded</Text>
              </p>
            </div>
          )}
        </div>

        <div className={classes.groupButton}>
          <Button secondary onClick={handleClose}>
            <Text i18nKey="common.close">Close</Text>
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

const styles = {
  container: {
    paddingTop: '25px',
    paddingBottom: '25px',
    width: '500px',
    paddingLeft: '32px',
    paddingRight: '32px',
    backgroundColor: '#ffffff',
    overflow: 'auto',
    boxShadow: '0 12px 33px 0 rgba(0, 0, 0, 0.1)'
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 22
  },
  title: {
    color: 'rgba(0,0,0,0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: '24px',
    lineHeight: '32px'
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    fill: '#999999',
    justifyContent: 'flex-end'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  text: {
    color: 'rgba(0,0,0,0.89)',
    marginBottom: 10
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '25px'
  },
  bodyMain: {
    paddingLeft: '30px'
  },
  noBloomModule: {
    marginTop: '20px'
  }
};

BloomVersionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  bloomVersions: PropTypes.object.isRequired
};

export default withStyles(styles)(BloomVersionDialog);
