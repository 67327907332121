import { DRAWER_WIDTH } from "../helpers/constants";

export const selectStyles = {
  container: containerStyles => ({
    ...containerStyles,
    height: '40px',
    width: '100%'
  }),
  placeholder: placeholderStyles => ({
    ...placeholderStyles,
    color: '#000',
    fontSize: '14px',
    opacity: '0.89',
    height: '20px',
    lineHeight: '20px',
    alignItems: 'center'
  }),
  valueContainer: valueContainerStyles => ({
    ...valueContainerStyles,
    height: '40px',
    fontSize: '14px'
  }),
  menu: menuStyles => ({
    ...menuStyles,
    marginTop: 1,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px'
  }),
  option: (opinionStyles, state) => ({
    ...opinionStyles,
    height: '40px',
    fontSize: '14px'
  }),
  control: controlStyles => ({
    ...controlStyles,
    minHeight: '40px',
    height: '40px',
    fontSize: '14px'
  })
};

export const styles = {
  container: {
    paddingTop: '25px',
    paddingBottom: '25px',
    maxWidth: '616px',
    paddingLeft: '32px',
    paddingRight: '32px',
    backgroundColor: '#ffffff',
    overflow: 'visible',
    boxShadow: '0px 26px 64px rgba(0, 0, 0, 0.07)'
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8
  },
  title: {
    color: '#000000',
    fontFamily: 'Sofia Pro',
    fontWeight: 800,
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: '32px',
    alignItems: 'centre'
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    fill: '#999999',
    marginLeft: '95%',
    width: '13.5px',
    height: '13.5px'
  },
  coloredBody: {
    display: 'flex',
    justifyContent: 'centre',
    backgroundColor: 'rgba(53, 202, 226, 0.1)',
    borderRadius: 6,
    width: '396px',
    height: '69px',
    left: '522px',
    top: '323px'
  },
  body: {
    display: 'flex',
    justifyContent: 'centre',
    borderRadius: 6,
    width: '396px',
    height: '69px',
    left: '522px',
    top: '323px',
    marginBottom: 32
  },
  description: {
    color: '#000000',
    fontFamily: 'Sofia Pro',
    fontWeight: 400,
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: '20px',
    alignItems: 'centre',
    height: '40px',
    maxWidth: '367px',
    margin: '16px 0 0 16px'
  },
  reasonsTitle: {
    width: '396px',
    height: '20px',
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#384853',
    paddingBottom: '4px'
  },
  otherReasons: {
    fontFamily: 'Sofia Pro',
    fontWeight: 400,
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: '20px',
    alignItems: 'centre',
    height: '40px',
    maxWidth: '396px',
    paddingBottom: '4px',
    margin: '16px 0 24px 0'
  },
  otherReasonsInput: {
    '&:focus': {
      borderColor: '#35CAE2 !important'
    }
  },
  text: {
    color: 'rgba(0,0,0,0.89)',
    marginBottom: 10
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '25px'
  }
};

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

export const layoutStyles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#F2F2F2'
  },

  listItem: {
    paddingLeft: 32,
    justifyContent: 'center'
  },

  drawers: {
    transform: 'translate(-1%)',
    transition: 'transform 1000s ease-out'
  },
  selectDropdownOption: {
    overflowY: 'visible'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  avatarRoot: {
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '21px'
  },
  container: {
    height: '100%',
    display: 'flex',
    overflowY: 'scroll',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      width: '0.2em',
      backgroundColor: 'transparent'
    } /* Chrome, Safari and Opera */,
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: '0.2em',
        backgroundColor: '#F9FAFB '
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#808384',
        borderRadius: '5px'
      },
      'scrollbar-width': 'thin',
      'scrollbar-color': 'auto',
      '-ms-overflow-style': 'auto'
    }
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    boxShadow: 'none'
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH
    },
    width: '70%'
  },
  content: {
    flexGrow: 1
  },
  paperAnchorDockedLeft: {
    boxShadow: '0 0 6px 0 rgba(0,0,0,0.08)',
    borderRight: 'none'
  },
  badge: {
    width: 10,
    height: 10
  },
  badgeColor: {
    backgroundColor: 'red'
  },
  '@media print': {
    noPrint: {
      display: 'none !important'
    }
  },
  logo: {
    cursor: 'pointer'
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  htmlPopper: arrowGenerator('rgba(0,0,0,0.9)'),
  htmlTooltip: {
    padding: '1.5rem',
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: '#ffffff',
    maxWidth: 254,
    fontSize: theme.typography.pxToRem(12),
    '& b': {
      fontWeight: theme.typography.fontWeightMedium
    },
    borderRadius: '0.1rem',
    zIndex: 2000
  },
  salesDownloadTooltipText: {
    fontSize: '10px'
  },
  salesDownloadingTooltipTitle: {
    color: '#F7B500'
  },
  salesDownloadedTooltipTitle: {
    color: '#44D7B6'
  },
  salesDownloadTooltipIcon: {
    color: '#FDC300'
  },
  notificationContainer: {
    alignItems: 'center',
    borderBottom: '1px solid lightgray',
    display: 'flex',
    height: 90,
    padding: '10px'
  },
  icon: {
    marginRight: 15
  },
  salesNotice: {
    fontSize: '13px',
    fontWeight: 700
  },
  selected: {
    boxShadow: 'inset 5px 0px #FDC300'
  },
  intercomLauncher: {
    border: 'transparent',
    backgroundColor: 'transparent',
    color: '#0000008a'
  }
});
