/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { CircularProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Text } from 'mpharma-i18n';
import './App.css';
import ErrorBoundary from './common/components/ErrorBoundary';
import Home from './common/components/Home';
import Layout from './common/components/Layout';
import Notification from './common/components/Notification';
import Theme from './common/Theme';
import { useLocalStorage } from 'react-use';
import useAppMountStatus from './hooks/useAppMountStatus';
import useOfflineStatus from './hooks/useOfflineStatus';
import useSalesHistoryDownloadsBroadcastChannel from './hooks/useSalesHistoryDownloadsBroadcastChannel';
import useInventoryDownloadsBroadcastChannel from './hooks/useInventoryLevelDownloadsBroadcastChannel';
import useLostSalesCountChannel from './hooks/useLostSalesCountChannel';
import AuthWorker from './workers/auth.worker.js';
import ResourceDownloadWorker from './workers/download.worker.js';
import { APP_ROLES } from './helpers/roles';
import {
  API_ROOT,
  BASE_NAME,
  INTERCOM_APP_ID,
  links,
  CURRENT_PRICE_UPLOAD_COUNTRY,
  PRICE_UPLOAD_PROCESSING_STATUS,
  CURRENT_PRICE_UPLOAD_FACILITY
} from './helpers/constants';
import { getCookie, showCheckFINotification, showExpiryNotification, showMpharmaWrappedPrompt } from './helpers/utils';
import {
  getResourceCount,
  saveNewPrices,
  updateInventory,
  updateFacilityUser,
  updateInventoryQuantities
} from './db/utils';
import { normalizeFacilities } from './helpers/normalizers';
import { get } from './admin/api';
import { requestSalesHistoryFile, requestInventoryLevelDownloadFile, clearBloomDB } from './common/state/auth/reducers';
import LogoutScreen from './common/components/LogoutScreen';
import MixpanelProvider from './common/components/Mixpanel/Provider';
import OfflineNotification from './common/components/OfflineNotification';
import UnavailableService from './common/components/UnavailableService';
import moment from 'moment';
import OfflinePinModal from './common/components/OfflinePinModal';
import { ReactComponent as GreenSmiley } from './images/GreenSmiley.svg';
import { ReactComponent as PriceUploadErrorAlertIcon } from './images/PriceUploadErrorAlertIcon.svg';
import usePriceUploadProgressBroadcastChannel from './hooks/usePriceUploadProgressBroadcastChannel';
import useDeliveryNoteUploadProgressBroadcastChannel from './hooks/useDeliveryNoteUploadProgressBroadcastChannel';
import useStockUploadProgressBroadcastChannel from './hooks/useStockUploadProgressBroadcastChannel';
import useCustomProductUploadProgressBroadcastChannel from './hooks/useCustomProductUploadProgressBroadcastChannel';
import MCAFeedbackDialog from './common/components/MCAFeedbackDialog/MCAFeedbackDialog';
import SyncDataNotifications from './common/components/SyncDataNotifications.js';
import useDownloadProgress from './hooks/useDownloadProgress.js';

const authWorker = new AuthWorker();
const resourceDownloadWorker = new ResourceDownloadWorker();

const idToken = getCookie('idToken');

function App({ classes }) {
  const { online } = useOfflineStatus();
  const { appLoading, errorMessage } = useAppMountStatus();
  const [loading, setLoading] = useState(false);
  const { priceUpdateProgress, setPriceUpdateProgress } = useDownloadProgress(resourceDownloadWorker);
  const [salesHistoryLink, setSalesHistoryLink] = useState(null);
  const [inventoryLevelDownloadLink, setInventoryLevelDownloadLink] = useState(null);
  const [salesLinkReqIntervalId, setSalesLinkReqIntervalId] = useState(null);
  const [inventoryLevelDownloadLinkReqIntervalId, setInventoryLevelDownloadLinkReqIntervalId] = useState(null);
  const [openedNotificationDrawer, setOpenedNotificationDrawer] = useState(false);
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [openExpiringSoonNotification, setOpenExpiringSoonNotification] = useState(false);
  const [openExpiredProductsNotification, setOpenExpiredProductsNotification] = useState(false);
  const [openNewPriceNotification, setOpenNewPriceNotification] = useState(false);
  const [downloadingNewPrice, setDownloadingNewPrice] = useState(false);
  const [openPriceUpdateModal, setOpenPriceUpdateModal] = useState(false);
  const [openPriceUploadErrorNotification, setOpenPriceUploadErrorNotification] = useState(true);
  const [salesHistoryBroadChanId, setSalesHistoryBroadChanId] = useSalesHistoryDownloadsBroadcastChannel();
  const [
    inventoryLevelDownloadBroadChanId,
    setInventoryLevelDownloadBroadChanId
  ] = useInventoryDownloadsBroadcastChannel();
  const [unsyncedLostSaleCount, setUnsyncedLostSaleCount] = useLostSalesCountChannel();
  const [showFIReminder, setshowFIReminder] = useState(false);
  const [showWrappedNotification, setshowWrappedNotification] = useState(false);

  const [newMCAFeedback, setNewMCAFeedback] = useState(false);
  const [showMCAFeedbackDialog, setShowMCAFeedbackDialog] = useState(false);
  const [user, setUser] = useLocalStorage('bloom:user');
  const [configs, setConfigs] = useLocalStorage('bloom:configs');
  const hasTriggeredRefreshToRemountMicroApps = useRef(user && configs);
  const [facility, setFacility] = useLocalStorage('bloom:facility');
  const [salesLocalStorageHistoryObjectId, setSalesLocalStorageHistoryObjectId] = useLocalStorage(
    'salesHistoryObjectId',
    ''
  );
  const [inventoryLevelDownloadObjectId, setInventoryLevelDownloadObjectId] = useLocalStorage(
    'inventoryLevelDownloadTrackerId'
  );
  const [lastInventoryUpdate, setLastInventoryUpdate] = useLocalStorage('bloom:lastInventoryUpdate');
  const [pricesUpdated, setPricesUpdated] = useLocalStorage('bloom:pricesUpdated');
  const [, setLastQuantitiesUpdate] = useLocalStorage('bloom:lastQuantitiesUpdate');
  const [, setNumberOfAttemptCallToGetQuantity] = useLocalStorage('numberOfAttemptCallToGetQuantity', 0);
  const [expiredListUpdated, setExpiredListUpdated] = useLocalStorage('bloom:expiredListUpdated');
  const [expiringListUpdated, setExpiringListUpdated] = useLocalStorage('bloom:expiringListUpdated');
  const [, setPriceUpdateLocal] = useLocalStorage('bloom:priceUpdateProgress');
  const [, setOfflineLogout] = useLocalStorage('bloom:offlineLoggedOut', false);
  const [openNewQuantityNotification, setOpenNewQuantityNotification] = useState(false);
  const {
    priceUploadProgress,
    setPriceUploadProgress,
    priceErrorCount,
    setPriceErrorCount,
    pricePageSwitched,
    setPricePageSwitched,
    priceUploadProcessingStatus,
    setPriceUploadProcessingStatus,
    priceUploadCountry,
    uploadProgressChannel,
    totalUploadRecords,
    priceUploadFacilityId,
    isBulkPriceUpload,
    priceUploadErrorMessage
  } = usePriceUploadProgressBroadcastChannel();

  const [
    deliveryNoteUploadProgress,
    setDeliveryNoteUploadProgress,
    deliveryNoteErrorCount,
    setDeliveryNoteErrorCount,
    deliveryNotePageSwitched,
    setDeliveryNotePageSwitched
  ] = useDeliveryNoteUploadProgressBroadcastChannel();
  const [
    stockUploadProgress,
    setStockUploadProgress,
    stockErrorCount,
    setStockErrorCount,
    stockPageSwitched,
    setStockPageSwitched
  ] = useStockUploadProgressBroadcastChannel();
  const [
    customProductUploadProgress,
    setCustomProductUploadProgress,
    customProductErrorCount,
    setCustomProductErrorCount,
    customProductPageSwitched,
    setCustomProductPageSwitched
  ] = useCustomProductUploadProgressBroadcastChannel();
  const contractType = user && facility?.entities?.facilities[user?.facility_id]?.contract_type;

  const nextShowDate = localStorage.getItem('bloom:fiMessageNextShowDate');

  const facilityObject = facility && user && facility.entities && facility.entities.facilities[user.facility_id];

  const previousUserFacilityID = localStorage.getItem('bloom:currentUserFacilityId');

  async function getMCAFeedback() {
    const BASE_URL = `${API_ROOT}/insights-bff/performance/mca-user-reviews?user_id=${user?.id}`;
    try {
      const mcaFeedbackData = await get(BASE_URL, { withCredentials: true });
      if (mcaFeedbackData && mcaFeedbackData?.results[0]?.is_read !== true) {
        setNewMCAFeedback(mcaFeedbackData?.results[0]);
      } else {
        setNewMCAFeedback(false);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async function getPriceUpdates(facilityId, lastInventoryUpdateParam) {
    const BASE_URL = `${API_ROOT}/inventory-bff/productaliases/new-prices/?facility_id=${facilityId}&created_after=${lastInventoryUpdateParam}`;
    try {
      const data = await get(BASE_URL, { withCredentials: true });
      if (data?.count > 0) {
        setDownloadingNewPrice(true);
      } else {
        return;
      }

      async function fetchRecords(responseData) {
        await saveNewPrices(responseData.results);
        responseData.results.forEach(result => {
          updateInventory(result.uuid, result);
        });

        if (!responseData.next) {
          const timestamp = new Date().toISOString();
          setLastInventoryUpdate(timestamp);
          setPricesUpdated(true);
          setDownloadingNewPrice(false);
          setOpenNewPriceNotification(true);
          return;
        }

        const nextUrl = new URL(responseData.next);
        let page = nextUrl.searchParams.get('page');
        //calculate the progress percentage.
        let priceDownloadPercentage = ((page * 25) / data.count) * 100;
        setPriceUpdateProgress({ ...priceUpdateProgress, progress: Math.trunc(priceDownloadPercentage) });

        const record = await get(`${BASE_URL}&page=${nextUrl.searchParams.get('page')}`, {
          withCredentials: true
        });

        return fetchRecords(record);
      }

      await fetchRecords(data);
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          facilityId: facilityId,
          lastFetchDate: lastInventoryUpdateParam
        }
      });
      setOpenPriceUpdateModal(false);
      // Retry after 10 seconds
      setTimeout(() => {
        if (!pricesUpdated) {
          getPriceUpdates(user.facility_id, lastInventoryUpdateParam);
        }
      }, 10000);
    }
  }

  const getLastFetchedDate = () => {
    try {
      const data = localStorage.getItem('bloom:lastQuantitiesUpdate');
      return JSON.parse(data);
    } catch (error) {
      Sentry.captureMessage(error?.message);
      return undefined;
    }
  };

  async function getQuantityUpdates(facilityId) {
    let lastFetchDate = getLastFetchedDate();
    if (lastFetchDate) {
      const BASE_URL = `${API_ROOT}/inventory-bff/productaliases/?facility_id=${facilityId}&since=${lastFetchDate}`;
      try {
        const data = await get(BASE_URL, { withCredentials: true });

        if (data && data.count <= 0) {
          const timestamp = new Date().toISOString();
          setLastQuantitiesUpdate(timestamp);
          return;
        }

        await fetchNewQuantityRecords(data, BASE_URL);
      } catch (error) {
        let attempted = parseInt(localStorage.getItem('numberOfAttemptCallToGetQuantity'));
        Sentry.captureException(error, {
          tags: {
            facilityId: facilityId,
            lastFetchDate: lastFetchDate,
            attempted: attempted
          }
        });
        if (attempted <= 3) {
          getQuantityUpdates(user.facility_id);
          setNumberOfAttemptCallToGetQuantity(attempted + 1);
        } else {
          setNumberOfAttemptCallToGetQuantity(0);
        }
      }
    } else {
      const timestamp = new Date().toISOString();
      setLastQuantitiesUpdate(timestamp);
    }
  }

  async function fetchNewQuantityRecords(responseData, baseURL) {
    responseData.results.forEach(result => {
      const records = {
        quantity: result.quantity,
        hasNewQuantity: true
      };
      updateInventoryQuantities(result.uuid, records);
    });

    if (!responseData.next) {
      const timestamp = new Date().toISOString();
      setLastQuantitiesUpdate(timestamp);
      setOpenNewQuantityNotification(true);
      return;
    }

    const nextUrl = new URL(responseData.next);
    const record = await get(`${baseURL}&page=${nextUrl.searchParams.get('page')}`, {
      withCredentials: true
    });

    return fetchNewQuantityRecords(record);
  }

  useEffect(() => {
    if (user && online) {
      getMCAFeedback();
    }
  }, [user]);

  useEffect(() => {
    setPriceUploadProgress(priceUploadProgress);
  }, [priceUploadProgress]);

  useEffect(() => {
    setPriceUploadProcessingStatus(priceUploadProcessingStatus);
  }, [priceUploadProcessingStatus]);

  useEffect(() => {
    setDeliveryNoteUploadProgress(deliveryNoteUploadProgress);
  }, [deliveryNoteUploadProgress]);

  useEffect(() => {
    setStockUploadProgress(stockUploadProgress);
  }, [stockUploadProgress]);

  useEffect(() => {
    setCustomProductUploadProgress(customProductUploadProgress);
  }, [customProductUploadProgress]);

  useEffect(() => {
    setPriceErrorCount(priceErrorCount);
  }, [priceErrorCount]);

  useEffect(() => {
    setDeliveryNoteErrorCount(deliveryNoteErrorCount);
  }, [deliveryNoteErrorCount]);

  useEffect(() => {
    setCustomProductErrorCount(customProductErrorCount);
  }, [customProductErrorCount]);

  useEffect(() => {
    setStockErrorCount(stockErrorCount);
  }, [stockErrorCount]);

  useEffect(() => {
    setPricePageSwitched(pricePageSwitched);
  }, [pricePageSwitched]);

  useEffect(() => {
    setDeliveryNotePageSwitched(deliveryNotePageSwitched);
  }, [deliveryNotePageSwitched]);

  useEffect(() => {
    setStockPageSwitched(stockPageSwitched);
  }, [stockPageSwitched]);

  useEffect(() => {
    setCustomProductPageSwitched(customProductPageSwitched);
  }, [customProductPageSwitched]);

  useEffect(() => {
    setPriceUpdateLocal(priceUpdateProgress.progress);
  }, [priceUpdateProgress.progress]);

  useEffect(() => {
    if (online && !pricesUpdated && user && user.facility_id && !user.is_staff) {
      if (lastInventoryUpdate) {
        getPriceUpdates(user.facility_id, lastInventoryUpdate);
      } else {
        const timestamp = new Date().toISOString();
        setLastInventoryUpdate(timestamp);
      }
    }
  }, [online, user?.facility_id]);

  useEffect(() => {
    if (online && user?.facility_id) {
      getQuantityUpdates(user?.facility_id);
    }
  }, [online]);

  useEffect(() => {
    setOpenedNotificationDrawer(false);
  }, []);
  useEffect(() => {
    if (salesLocalStorageHistoryObjectId) {
      requestSalesHistoryLink(salesLocalStorageHistoryObjectId);
    }
  }, [salesLocalStorageHistoryObjectId]);

  useEffect(() => {
    if (inventoryLevelDownloadObjectId) {
      requestInventoryLevelDownloadLink(inventoryLevelDownloadObjectId);
    }
  }, [inventoryLevelDownloadObjectId]);

  useEffect(() => {
    if (salesHistoryBroadChanId) {
      requestSalesHistoryLink(salesHistoryBroadChanId);
    }
  }, [salesHistoryBroadChanId]);

  useEffect(() => {
    if (inventoryLevelDownloadBroadChanId) {
      requestInventoryLevelDownloadLink(inventoryLevelDownloadBroadChanId);
    }
  }, [inventoryLevelDownloadBroadChanId]);

  useEffect(() => {
    if (inventoryLevelDownloadLink) {
      clearInterval(inventoryLevelDownloadLinkReqIntervalId);
      setInventoryLevelDownloadLinkReqIntervalId(null);
    }
  }, [inventoryLevelDownloadLink, inventoryLevelDownloadLinkReqIntervalId]);

  useEffect(() => {
    if (salesHistoryLink) {
      clearInterval(salesLinkReqIntervalId);
      setSalesLinkReqIntervalId(null);
    }
  }, [salesHistoryLink, salesLinkReqIntervalId]);

  const requestSalesHistoryLink = requestId => {
    setSalesLinkReqIntervalId(
      setInterval(() => {
        requestSalesHistoryFile(requestId).then(results => {
          if (results?.s3_download_link) {
            if (!salesHistoryLink || salesHistoryLink !== results.s3_download_link) {
              setSalesHistoryLink(results.s3_download_link);
            }
          }
        });
      }, 3000)
    );
  };

  const requestInventoryLevelDownloadLink = requestId => {
    setInventoryLevelDownloadLinkReqIntervalId(
      setInterval(() => {
        requestInventoryLevelDownloadFile(requestId).then(results => {
          if (results?.s3_download_link) {
            if (!inventoryLevelDownloadLink || inventoryLevelDownloadLink !== results.s3_download_link) {
              setInventoryLevelDownloadLink(results.s3_download_link);
            }
          }
        });
      }, 3000)
    );
  };

  const cancelSalesHistoryDownloadRequest = () => {
    setSalesHistoryLink('');
    setSalesLocalStorageHistoryObjectId(null);
    setSalesHistoryBroadChanId(null);
    setSalesLinkReqIntervalId(null);
    setOpenedNotificationDrawer(false);
  };

  const cancelInventoryLevelDownloadRequest = () => {
    setInventoryLevelDownloadLink('');
    setInventoryLevelDownloadObjectId(null);
    setInventoryLevelDownloadBroadChanId(null);
    setInventoryLevelDownloadLinkReqIntervalId(null);
    setOpenedNotificationDrawer(false);
  };

  const endInventoryLevelDownloadBroadChannnel = () => {
    setInventoryLevelDownloadBroadChanId(null);
  };

  authWorker.onmessage = e => {
    if (e.data.sessionExpired === true && online) {
      localStorage.removeItem('bloom:user');
      setTimeout(() => {
        setUser(undefined);
        setConfigs(undefined);
      }, 500);
    } else if (e.data.sessionExpired === false && !user && online) {
      getUserAndConfigData();
    }
  };

  useEffect(() => {
    if (unsyncedLostSaleCount) {
      setUnsyncedLostSaleCount(unsyncedLostSaleCount);
    }
  }, [unsyncedLostSaleCount]);

  async function getUserAndConfigData() {
    setLoading(true);
    try {
      const [userResponse, configResponse] = await Promise.all(links.map(link => get(`${API_ROOT}/${link.url}`)));
      setUser(userResponse);
      setConfigs(configResponse);
      setOfflineLogout(false);
      localStorage.setItem('redirected', 'no');
      hasTriggeredRefreshToRemountMicroApps.current = false;
      if (!userResponse.is_staff) {
        const { content } = await get(`${API_ROOT}/facilities-bff/general/facilities/${userResponse.facility_id}/`);
        setFacility(normalizeFacilities(content));
      }

      await updateFacilityUser(userResponse.id, userResponse);
    } catch (e) {
      console.error('An error occurred', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (online && (!configs || !user || redirected === 'yes')) {
      getUserAndConfigData();
    }
  }, []);

  function clearStorageIfFacilityChanged() {
    if (online && !user.is_staff) {
      if (previousUserFacilityID && previousUserFacilityID !== user.facility_id) {
        clearBloomDB(user.facility_id);
      }
    }
  }
  function terminateWorkerIfAdmin() {
    if (user?.scopes?.includes(APP_ROLES.admin)) {
      resourceDownloadWorker.terminate();
    }
  }
  function postMessageToWorkers() {
    resourceDownloadWorker.postMessage({
      cmd: 'ready',
      id: user.facility_id,
      idToken: getCookie('idToken'),
      facilityName: facilityObject?.name
    });
    authWorker.postMessage({ cmd: 'ready', idToken: getCookie('idToken') });
  }
  useEffect(() => {
    if (user) {
      clearStorageIfFacilityChanged();
      terminateWorkerIfAdmin();
      postMessageToWorkers();
    }
  }, [user, previousUserFacilityID]);

  /*###################################################*/
  /*############### Global Catches        #############*/
  /*###################################################*/
  // Global catch for blurring keyboard on pressing 'Enter'
  useEffect(() => {
    function handleEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    }

    document.addEventListener('keyup', handleEnter, false);

    return function cleanup() {
      document.removeEventListener('keyup', handleEnter, false);
    };
  });

  useEffect(() => {
    if (!loading && user && configs && !hasTriggeredRefreshToRemountMicroApps.current) {
      window.location.reload();
      hasTriggeredRefreshToRemountMicroApps.current = true;
    }
  }, [user, configs, loading]);

  useEffect(() => {
    if (user && configs) {
      Sentry.configureScope(function(scope) {
        scope.setUser({
          id: user.id
        });
      });
    }
  }, [user, configs]);

  function onTryAgain() {
    window.location.reload();
  }

  const hasDayPassed = date => {
    if (!date) return true;
    return moment().diff(moment(date), 'days') >= 1;
  };

  useEffect(() => {
    if (!user?.scopes?.includes(APP_ROLES.admin)) {
      getExpiringProductsCount();
      getExpiredProductsCount();
    }
  }, [user]);

  useEffect(() => {
    if (!nextShowDate) {
      setshowFIReminder(true);
    } else {
      const today = new Date();
      if (today > new Date(JSON.parse(nextShowDate))) {
        setshowFIReminder(true);
      } else {
        setshowFIReminder(false);
      }
    }
  }, [nextShowDate]);

  async function getExpiringProductsCount() {
    const expiringProductsCount = await getResourceCount('expiring_soon');
    if (expiringProductsCount > 0 && hasDayPassed(expiringListUpdated)) {
      setOpenExpiringSoonNotification(true);
    }
  }

  async function getExpiredProductsCount() {
    const expiredProductsCount = await getResourceCount('expired_products');
    if (expiredProductsCount > 0 && hasDayPassed(expiredListUpdated)) {
      setOpenExpiredProductsNotification(true);
    }
  }

  const updateNextFIReminderDate = () => {
    const duration = 7; //Show reminder every 7 days after dismissal.
    const showNext = new Date().setDate(new Date().getDate() + duration);
    localStorage.setItem('bloom:fiMessageNextShowDate', showNext);
  };

  const hideWrappedNotification = () => {
    setshowWrappedNotification(false);
    localStorage.setItem('bloom:fi-hasSeenWrappedNotification', true);
  };

  const cachedCountry =
    localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY) &&
    localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY) !== 'undefined'
      ? localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY)
      : '';
  function handleGoToUpload(history) {
    if (isBulkPriceUpload || priceUploadCountry || cachedCountry) {
      history.push(`/inventory/price-list/${priceUploadCountry || cachedCountry}`);
      setOpenedNotificationDrawer(false);
    } else if (priceUploadFacilityId || localStorage.getItem(CURRENT_PRICE_UPLOAD_FACILITY)) {
      history.push(
        `/inventory/facilities/${priceUploadFacilityId || localStorage.getItem(CURRENT_PRICE_UPLOAD_FACILITY)}/profile`
      );
      setOpenedNotificationDrawer(false);
    }

    uploadProgressChannel.postMessage({
      shouldDisplayUploadProgress: true,
      isBulkPriceUpload
    });
  }

  return (
    <ErrorBoundary>
      {!loading && <OfflinePinModal />}
      {loading && (
        <div className={classes.root}>
          <CircularProgress size={50} classes={{ colorPrimary: classes.primary }} />
          <br />
          <p>
            <Text i18nKey="home.initializeApp">Initializing App</Text>
          </p>
        </div>
      )}
      {!idToken && !loading && <LogoutScreen onSignout={onTryAgain} />}

      {!loading && user && configs && (
        <MuiThemeProvider theme={Theme}>
          <MixpanelProvider>
            <Router basename={`${BASE_NAME}`}>
              <IntercomProvider appId={INTERCOM_APP_ID}>
                <Route
                  path="/:path(home|)"
                  render={props => <Home history={props.history} user={user} contractType={contractType} />}
                />
                <Route
                  path="/home"
                  exact
                  render={() => {
                    return <Redirect to="/" />;
                  }}
                />

                <Switch>
                  <Route
                    path="/account"
                    render={({ location }) => {
                      //shape of deconstructed props ==>  { history, location, match, staticContext }
                      //catching all direct calls to '/account', prefixing them with '/inventory' and redirecting to the new route
                      return <Redirect to={`/inventory${location.pathname}`} />;
                    }}
                  />

                  <Route
                    path="/bloom/:path"
                    render={({ match }) => {
                      return <Redirect to={`/${match.params.path}`} />;
                    }}
                  />
                  <Route
                    path="/bloom"
                    render={() => {
                      return <Redirect to="/" />;
                    }}
                  />

                  {/* NOTE: Adding a couple of /analytics routes to redirect to /facility-insights before the route is removed */}
                  <Route
                    path="/analytics/reports/:path"
                    render={({ match }) => {
                      return <Redirect to={`/facility-insights/reports/${match.params.path}`} />;
                    }}
                  />
                  <Route
                    path="/analytics/:path"
                    render={({ match }) => {
                      return <Redirect to={`/facility-insights/${match.params.path}`} />;
                    }}
                  />

                  <Route
                    path="/analytics"
                    render={() => {
                      return <Redirect to="/facility-insights/overview" />;
                    }}
                  />
                  {/*End /analytics redirects */}

                  <Route
                    path="*"
                    render={props => (
                      <Layout
                        isAdmin={user?.is_staff}
                        setShowNotificationDrawer={setShowNotificationDrawer}
                        showNotificationDrawer={showNotificationDrawer}
                        resourceDownloadWorker={resourceDownloadWorker}
                        history={props.history}
                        cancelSalesHistoryDownloadRequest={cancelSalesHistoryDownloadRequest}
                        cancelInventoryLevelDownloadRequest={cancelInventoryLevelDownloadRequest}
                        salesLinkReqIntervalId={salesLinkReqIntervalId}
                        salesHistoryLink={salesHistoryLink}
                        openedNotificationDrawer={openedNotificationDrawer}
                        setOpenedNotificationDrawer={setOpenedNotificationDrawer}
                        salesHistoryBroadChanId={salesHistoryBroadChanId}
                        salesLocalStorageHistoryObjectId={salesLocalStorageHistoryObjectId}
                        inventoryLevelDownloadBroadChanId={inventoryLevelDownloadBroadChanId}
                        inventoryLevelDownloadObjectId={inventoryLevelDownloadObjectId}
                        inventoryLevelDownloadLink={inventoryLevelDownloadLink}
                        inventoryLevelDownloadLinkReqIntervalId={inventoryLevelDownloadLinkReqIntervalId}
                        endInventoryLevelDownloadBroadChannnel={endInventoryLevelDownloadBroadChannnel}
                        unsyncedLostSaleCount={unsyncedLostSaleCount}
                        location={props.location.pathname}
                        openExpiringSoonNotification={openExpiringSoonNotification}
                        openExpiredProductsNotification={openExpiredProductsNotification}
                        openPriceUpdateModal={openPriceUpdateModal}
                        showFIReminder={showFIReminder}
                        updateNextFIReminderDate={updateNextFIReminderDate}
                        setshowFIReminder={setshowFIReminder}
                        showWrappedNotification={showWrappedNotification}
                        setshowWrappedNotification={setshowWrappedNotification}
                        hideWrappedNotification={hideWrappedNotification}
                        newMCAFeedback={newMCAFeedback}
                        showMCAFeedbackDialog={showMCAFeedbackDialog}
                        setShowMCAFeedbackDialog={setShowMCAFeedbackDialog}
                        fileUploadsProgress={{
                          price: {
                            progress: priceUploadProgress,
                            errorCount: priceErrorCount,
                            pageSwitched: pricePageSwitched,
                            processingStatus: priceUploadProcessingStatus
                          },
                          deliveryNote: {
                            progress: deliveryNoteUploadProgress,
                            errorCount: deliveryNoteErrorCount,
                            pageSwitched: deliveryNotePageSwitched
                          },
                          stock: {
                            progress: stockUploadProgress,
                            errorCount: stockErrorCount,
                            pageSwitched: stockPageSwitched
                          },
                          customProduct: {
                            progress: customProductUploadProgress,
                            errorCount: customProductErrorCount,
                            pageSwitched: customProductPageSwitched
                          }
                        }}
                      >
                        {appLoading && (
                          <div className={classes.loading}>
                            <CircularProgress size={50} classes={{ colorPrimary: classes.primary }} />
                            <br />
                            <p>
                              <Text i18nKey="home.loading">Loading App</Text>
                            </p>
                          </div>
                        )}

                        {Boolean(errorMessage) && (
                          <div className={classes.loading}>
                            <p>
                              <Text i18nKey={errorMessage} />
                            </p>
                          </div>
                        )}

                        <div id="inventory" />
                        <div id="pos" />
                        <div id="orders" />
                        <div id="facility-insights" />
                        <div
                          id="lab-test"
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        />
                        <div id="health-services" />

                        <>
                          <UnavailableService service="inventory" online={online} facilityObject={facilityObject} />
                          {showFIReminder && showCheckFINotification(window.location.pathname, user) && (
                            <Notification
                              action={<Text i18nKey="notifications.checkFacilityInsights.action">View the list</Text>}
                              link="/facility-insights/overview?from=reminder"
                              message={
                                <>
                                  <b style={{ fontFamily: 'Sofia Pro Bold' }}>
                                    <Text i18nKey="notifications.checkFacilityInsights.title">
                                      Have you checked your profits for this week?
                                    </Text>
                                  </b>
                                  <br />
                                  <Text i18nKey="notifications.checkFacilityInsights.subtitle">
                                    Open Facility Insights to check.
                                  </Text>
                                </>
                              }
                              textStyle={{ width: 'unset', lineHeight: '1.7em' }}
                              icon={<GreenSmiley style={{ margin: 2 }} />}
                              success
                              handleClose={() => {
                                updateNextFIReminderDate();
                                setshowFIReminder(false);
                              }}
                            />
                          )}
                          {showWrappedNotification && showMpharmaWrappedPrompt(user) && (
                            <Notification
                              action={<Text i18nKey="notifications.mPharmaWrapped.action">Get Started</Text>}
                              link="/facility-insights/reports?i=wrapped"
                              message={
                                <>
                                  <b style={{ fontFamily: 'Sofia Pro Bold' }}>
                                    <Text i18nKey="notifications.mPharmaWrapped.title">
                                      Introducing the new #mPharmaWrapped
                                    </Text>
                                  </b>
                                  <br />
                                  <Text i18nKey="notifications.mPharmaWrapped.subtitle">
                                    Time to reflect on the past year
                                  </Text>
                                </>
                              }
                              textStyle={{ width: 'unset', lineHeight: '1.7em' }}
                              icon={<GreenSmiley style={{ margin: 2 }} />}
                              success
                              handleClose={() => {
                                hideWrappedNotification();
                              }}
                            />
                          )}
                          <SyncDataNotifications
                            history={props.history}
                            setShowNotificationDrawer={setShowNotificationDrawer}
                          />
                          {newMCAFeedback && (
                            <Notification
                              action={<Text i18nKey="notifications.MCAFeedback.action">See feedback</Text>}
                              link={() => setShowMCAFeedbackDialog(true)}
                              message={
                                <>
                                  <b style={{ fontFamily: 'Sofia Pro Bold' }}>
                                    <Text
                                      i18nKey="home.salutation"
                                      variables={{
                                        firstName: `${user?.first_name}`
                                      }}
                                    >
                                      Hi
                                    </Text>
                                    , <Text i18nKey="notifications.MCAFeedback.title">you have new feedback</Text>
                                  </b>
                                  <br />
                                  <Text i18nKey="notifications.MCAFeedback.subtitle">
                                    Click the link below to see it.
                                  </Text>
                                </>
                              }
                              textStyle={{ width: 'unset', lineHeight: '1.7em' }}
                              icon={<GreenSmiley style={{ margin: 2 }} />}
                              success
                              handleClose={() => setNewMCAFeedback(false)}
                            />
                          )}

                          {showMCAFeedbackDialog && (
                            <MCAFeedbackDialog
                              close={() => {
                                setShowMCAFeedbackDialog(false);
                                setNewMCAFeedback(false);
                              }}
                              feedback={newMCAFeedback}
                            />
                          )}

                          {openExpiredProductsNotification && showExpiryNotification(window.location.pathname) && (
                            <Notification
                              action={<Text i18nKey="notifications.expiredProducts.action">View the list</Text>}
                              link="/inventory/expired-products"
                              message={
                                <Text i18nKey="notifications.expiredProducts.message">
                                  You have a number of products that have expired
                                </Text>
                              }
                              critical
                              handleClose={() => {
                                setExpiredListUpdated(new Date().toISOString());
                                setOpenExpiredProductsNotification(false);
                              }}
                            />
                          )}

                          {openExpiringSoonNotification && showExpiryNotification(window.location.pathname) && (
                            <Notification
                              action={<Text i18nKey="notifications.expiringSoon.action">View the list</Text>}
                              link="/inventory/expiring-soon"
                              message={
                                <Text i18nKey="notifications.expiringSoon.message">
                                  You have a number of products that are expiring soon
                                </Text>
                              }
                              warning
                              handleClose={() => {
                                setExpiringListUpdated(new Date().toISOString());
                                setOpenExpiringSoonNotification(false);
                              }}
                            />
                          )}
                          {openNewPriceNotification && (
                            <Notification
                              action={<Text i18nKey="notifications.newPrice.action">View the list</Text>}
                              link="/inventory/new-prices"
                              message={
                                <Text i18nKey="notifications.newPrice.message">
                                  You have a number of products that have new prices
                                </Text>
                              }
                              info
                              handleClose={() => setOpenNewPriceNotification(false)}
                            />
                          )}

                          {downloadingNewPrice && (
                            <Notification
                              message={<Text i18nKey="home.downloadingNewPrices">Downloading new prices</Text>}
                              info
                              handleClose={() => setDownloadingNewPrice(false)}
                            />
                          )}

                          <OfflineNotification facilityObject={facilityObject} />
                        </>
                        {openExpiringSoonNotification && showExpiryNotification(window.location.pathname) && (
                          <Notification
                            action={<Text i18nKey="notifications.expiringSoon.action">View the list</Text>}
                            link="/inventory/expiring-soon"
                            message={
                              <Text i18nKey="notifications.expiringSoon.message">
                                You have a number of products that are expiring soon
                              </Text>
                            }
                            warning
                            handleClose={() => {
                              setExpiringListUpdated(new Date().toISOString());
                              setOpenExpiringSoonNotification(false);
                            }}
                          />
                        )}
                        {openNewQuantityNotification && (
                          <Notification
                            action={<Text i18nKey="notifications.newPrice.action">View the list</Text>}
                            link="/inventory/all"
                            message={
                              <Text i18nKey="notifications.newQuantity.message">
                                You have a number of products that have new quantities
                              </Text>
                            }
                            info
                            handleClose={() => setOpenNewQuantityNotification(false)}
                          />
                        )}

                        {downloadingNewPrice && (
                          <Notification
                            message={<Text i18nKey="home.downloadingNewPrices">Downloading new prices</Text>}
                            info
                            handleClose={() => setDownloadingNewPrice(false)}
                          />
                        )}

                        {priceUploadProcessingStatus === PRICE_UPLOAD_PROCESSING_STATUS.FAILED_VALIDATION &&
                          openPriceUploadErrorNotification && (
                            <Notification
                              critical
                              icon={<PriceUploadErrorAlertIcon style={{ position: 'absolute', top: 0, left: 0 }} />}
                              message={
                                <>
                                  <strong>
                                    <Text i18nKey="notifications.priceUploadError.title">
                                      Something happened with your price data upload
                                    </Text>
                                  </strong>
                                  <span style={{ display: 'block' }}>
                                    {priceUploadErrorMessage ? (
                                      <Text>{priceUploadErrorMessage}</Text>
                                    ) : (
                                      <Text
                                        i18nKey="notifications.priceUploadError.subtitle2"
                                        variables={{
                                          errorRecords: priceErrorCount,
                                          totalRecords: totalUploadRecords
                                        }}
                                      >
                                        Some records could not be processed
                                      </Text>
                                    )}
                                  </span>
                                </>
                              }
                              action={<Text i18nKey="notifications.priceUploadError.action">Go to upload</Text>}
                              link={() => handleGoToUpload(props.history)}
                              handleClose={() => setOpenPriceUploadErrorNotification(false)}
                            />
                          )}
                      </Layout>
                    )}
                  />
                </Switch>
              </IntercomProvider>
            </Router>
          </MixpanelProvider>
        </MuiThemeProvider>
      )}
    </ErrorBoundary>
  );
}

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    height: '100%',
    zIndex: '1000000',
    background: 'white',
    paddingTop: '50px',
    borderLeft: '1px solid',
    borderLeftColor: '#eee',
    marginTop: 'unset'
  },
  primary: {
    color: '#FF5001'
  },
  login: {
    marginTop: 20,
    fontSize: 16
  }
});
export default withStyles(styles)(App);
