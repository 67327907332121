import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Divider, CircularProgress } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import ProgressBar from './ProgressBar';
import Breadcrumb from './Breadcrumb';
import usePriceUploadProgressBroadcastChannel from '../../hooks/usePriceUploadProgressBroadcastChannel';
import CheckCircleIcon from '../../images/check_circle.png';
import { ReactComponent as DownloadIcon } from '../../images/Download.svg';
import { ReactComponent as InventoryIcon } from '../../images/Inventory-notifications.svg';
import { ReactComponent as UploadIcon } from '../../images/Upload.svg';
import { ReactComponent as PatientListIcon } from '../../images/PatientList-notifications.svg';
import { ReactComponent as POSIcon } from '../../images/POS-notifications.svg';
import { ReactComponent as ProgressErrorIcon } from '../../images/ProgressError.svg';
import { ReactComponent as ProgressSuccessIcon } from '../../images/ProgressSuccess.svg';
import { ReactComponent as PriceIcon } from '../../images/Price-notification.svg';
import { ReactComponent as FailedPriceUploadError } from '../../images/FailedPriceUploadError.svg';
import { Text } from 'mpharma-i18n';
import {
  PRICE_UPLOAD_PROCESSING_STATUS,
  CURRENT_PRICE_UPLOAD_COUNTRY,
  RESOURCE_TITLE,
  CURRENT_PRICE_UPLOAD_FACILITY,
  SALES_SYNC_PARTIAL,
  SALES_SYNC
} from '../../helpers/constants';
import useSalesSyncStatusBroadcastChannel from '../../hooks/useSalesSyncStatusBroadcastChannel';

function InProgressDrawer({ onClose, children }) {
  const progressStyles = {
    container: {
      marginLeft: 24,
      paddingRight: 24,
      width: 415
    },
    divider: {
      marginTop: 25
    }
  };

  return (
    <Fragment>
      <Breadcrumb onClose={onClose} title={<Text i18nKey="home.notifications">Notifications</Text>} />
      <Divider style={progressStyles.divider} />
      <div style={progressStyles.container} data-testid="in-progress-drawer-children">
        {children}
      </div>
    </Fragment>
  );
}

function FileProcessingDisplaySuccess({ classes, processingErrorCount, resourceTitle, priceUploadErrorMessage }) {
  if (priceUploadErrorMessage) {
    return <p className={classes.errorsText}>{priceUploadErrorMessage}</p>;
  }
  if (processingErrorCount > 0) {
    return <p className={classes.errorsText}>{`${processingErrorCount} records failed to be processed`}</p>;
  }
  return (
    <Text i18nKey="common.inprogress.fileUploadProcessing.success" variables={{ resourceTitle }}>
      <span>Your {resourceTitle} was successfully processed</span>
    </Text>
  );
}

function FileProcessingDisplayError({ classes, errorCount, handleViewProgress, priceUploadErrorMessage }) {
  return (
    <div>
      <div className={classes.errorCountContainer}>
        <div className={classes.failedValidationError}>
          <p className={classes.failedValidationErrorText}>
            <Text i18nKey="cs.error">Error</Text>
          </p>
        </div>

        <div className={classes.countText}>
          {priceUploadErrorMessage ? (
            <Text>{priceUploadErrorMessage}</Text>
          ) : (
            <Text>{errorCount} records failed to be processed</Text>
          )}
        </div>
      </div>
      <button className={classes.viewProgressButton} onClick={handleViewProgress}>
        <span>
          <Text>Go to upload</Text>
        </span>
        <ArrowForward />
      </button>
    </div>
  );
}

function FileProcessingDisplayInProgress({
  progress,
  classes,
  type,
  errorCount,
  resourceTitle,
  processingStatus,
  handleViewProgress
}) {
  const loadingDisplay = () => {
    if (progress) {
      return (
        <p className={classes.progressText}>
          {`${Number.parseFloat(progress).toFixed(2)}% `}
          {type === 'fileUploadProcessing' ? (
            <Text i18nKey="common.inprogress.fileUploadProcessing.progressText" variables={{ resourceTitle }}>
              <span>proccessed</span>
            </Text>
          ) : (
            <Text i18nKey={TEXT[type].progressText} variables={{ resourceTitle }}>
              {TEXT[type].progressText}
            </Text>
          )}
        </p>
      );
    }

    return (
      <div className={classes.progressText} style={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress
          style={{
            marginRight: 3,
            color: '#FDC302'
          }}
          size={12}
        />
        <span>{processingStatus?.toLowerCase().replace(/_/g, ' ')}...</span>
      </div>
    );
  };

  return (
    <Fragment>
      {progress > 0 ? <ProgressBar percentage={progress} /> : null}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {loadingDisplay()}
        {resourceTitle === RESOURCE_TITLE.PRICE_LIST && (
          <button className={classes.viewProgressButton} onClick={handleViewProgress}>
            <span>
              <Text i18nKey="common.inprogress.fileUploadProcessing.viewProgress">View progress</Text>
            </span>
            <ArrowForward />
          </button>
        )}
      </div>
      {errorCount && <p className={classes.errorsText}>{`Although there are ${errorCount} errors`}</p>}
    </Fragment>
  );
}

const TEXT = {
  download: {
    subtitle: `common.inprogress.download.subtitle`,
    progressText: 'common.inprogress.download.progressText',
    error: 'common.inprogress.download.error',
    success: `common.inprogress.download.success`
  },
  upload: {
    subtitle: 'common.inprogress.upload.subtitle',
    progressText: 'common.inprogress.upload.progressText',
    error: 'common.inprogress.upload.error',
    success: 'common.inprogress.upload.success'
  },
  fileUploadProcessing: {
    subtitle: 'common.inprogress.upload.subtitle',
    progressText: 'common.inprogress.upload.progressText',
    error: 'common.inprogress.upload.error',
    success: 'common.inprogress.upload.success'
  },
  sales: {
    title: 'common.inprogress.sales.title',
    downloadedTitle: 'common.inprogress.sales.downloadedTitle',
    subtitle: `common.inprogress.sales.subtitle`,
    downloadedSubtitle: 'common.inprogress.sales.downloadedSubtitle',
    progressText: 'common.inprogress.sales.progressText',
    error: 'common.inprogress.sales.error',
    success: 'common.inprogress.sales.success'
  },
  inventoryLevelDownload: {
    title: 'common.inprogress.inventoryLevelDownload.title',
    downloadedTitle: 'common.inprogress.inventoryLevelDownload.downloadedTitle',
    subtitle: `common.inprogress.inventoryLevelDownload.subtitle`,
    downloadedSubtitle: 'common.inprogress.inventoryLevelDownload.downloadedSubtitle',
    progressText: 'common.inprogress.inventoryLevelDownload.progressText',
    error: 'common.inprogress.inventoryLevelDownload.error',
    success: 'common.inprogress.inventoryLevelDownload.success'
  },
  salesSync: {
    title: 'common.inprogress.salesSync.title',
    subtitle: 'common.inprogress.salesSync.subtitle',
    partialSyncTitle: 'common.inprogress.salesSync.partialSyncTitle',
    partialSyncSubtitle: 'common.inprogress.salesSync.partialSyncSubtitle'
  }
};

function ProgressDisplay({
  type,
  classes,
  processingStatus,
  resourceTitle,
  errorCount,
  processingErrorCount,
  handleViewProgress,
  priceUploadErrorMessage,
  progress,
  subtitleToDisplay,
  iconToDisplay
}) {
  if (processingStatus === PRICE_UPLOAD_PROCESSING_STATUS.QUEUED_UPLOAD) return null;
  function progressDisplaySubtitleText() {
    if (type === 'fileUploadProcessing' && processingStatus === PRICE_UPLOAD_PROCESSING_STATUS.FAILED_VALIDATION) {
      return <Text>Something happened with your price upload</Text>;
    }
    return type === 'fileUploadProcessing' ? (
      <Text i18nKey="common.inprogress.fileUploadProcessing.title" variables={{ resourceTitle }}>
        <span>Your {resourceTitle} is processing</span>
      </Text>
    ) : (
      <Text i18nKey={subtitleToDisplay()} variables={{ resourceTitle }}>
        {subtitleToDisplay()}
      </Text>
    );
  }
  return (
    <Fragment>
      <div className={classes.container}>
        {iconToDisplay()}
        <div className={classes.midSection}>
          <p className={classes.subtitle}>{progressDisplaySubtitleText()}</p>

          {processingStatus === PRICE_UPLOAD_PROCESSING_STATUS.FAILED_VALIDATION ? (
            <FileProcessingDisplayError
              errorCount={errorCount ?? processingErrorCount}
              classes={classes}
              handleViewProgress={handleViewProgress}
              priceUploadErrorMessage={priceUploadErrorMessage}
            />
          ) : (
            <FileProcessingDisplayInProgress
              progress={progress}
              classes={classes}
              type={type}
              errorCount={errorCount}
              resourceTitle={resourceTitle}
              processingStatus={processingStatus}
              handleViewProgress={handleViewProgress}
            />
          )}
        </div>
      </div>
      <Divider className={classes.divider} />
    </Fragment>
  );
}

function ProgressRow({
  classes,
  id,
  type,
  progress,
  errorCount,
  success,
  resourceTitle,
  showCard = true,
  salesHistoryLink,
  salesLinkReqIntervalId,
  cancelSalesHistoryDownloadRequest,
  cancelInventoryLevelDownloadRequest,
  openedNotificationDrawer,
  setOpenedNotificationDrawer,
  inventoryLevelDownloadLink,
  inventoryLevelDownloadLinkReqIntervalId,
  processingErrorCount,
  processingStatus,
  closeNotificationDrawer,
  history
}) {
  const [clearId, setClearId] = useState('');
  const {
    uploadProgressChannel,
    priceUploadCountry,
    priceUploadFacilityId,
    isBulkPriceUpload,
    priceUploadErrorMessage
  } = usePriceUploadProgressBroadcastChannel();

  const ICON = {
    download: {
      default: <DownloadIcon className={classes.smallIcon} />,
      Inventory: <InventoryIcon className={classes.icon} />,
      Price: <PriceIcon className={classes.icon} />,
      'Sales history': <POSIcon className={classes.icon} />,
      'Patient list': <PatientListIcon className={classes.icon} />,
      'Promotions and Discounts': <POSIcon className={classes.icon} />,
      error: <ProgressErrorIcon className={classes.icon} />,
      errorSmall: <ProgressErrorIcon className={classes.smallIcon} />,
      success: <ProgressSuccessIcon className={classes.icon} />,
      successSmall: <ProgressSuccessIcon className={classes.smallIcon} />
    },
    upload: {
      default: <UploadIcon className={classes.smallIcon} />,
      Inventory: <InventoryIcon className={classes.icon} />,
      error: <ProgressErrorIcon className={classes.icon} />,
      success: <ProgressSuccessIcon className={classes.icon} />
    },
    fileUploadProcessing: {
      default: <UploadIcon className={classes.smallIcon} />,
      Inventory: <InventoryIcon className={classes.icon} />,
      error: <ProgressErrorIcon className={classes.icon} />,
      success: <ProgressSuccessIcon className={classes.icon} />
    },
    salesSync: {
      default: <UploadIcon className={classes.smallIcon} />
    }
  };

  function iconToDisplay() {
    if (processingStatus === PRICE_UPLOAD_PROCESSING_STATUS.FAILED_VALIDATION) {
      return <FailedPriceUploadError className={classes.icon} />;
    }
    return ICON[type][resourceTitle] || ICON[type].default;
  }

  function subtitleToDisplay() {
    if (success) return TEXT[type].success;
    return TEXT[type].subtitle;
  }

  function clearNotification(newClearId) {
    setClearId(newClearId);
  }

  const cachedCountry =
    localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY) &&
    localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY) !== 'undefined'
      ? localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY)
      : '';
  function handleViewProgress() {
    if (isBulkPriceUpload || priceUploadCountry || cachedCountry) {
      history.push(`/inventory/price-list/${priceUploadCountry || cachedCountry}`);
      closeNotificationDrawer();
    } else if (priceUploadFacilityId || localStorage.getItem(CURRENT_PRICE_UPLOAD_FACILITY)) {
      history.push(
        `/inventory/facilities/${priceUploadFacilityId || localStorage.getItem(CURRENT_PRICE_UPLOAD_FACILITY)}/profile`
      );
      closeNotificationDrawer();
    }
    uploadProgressChannel.postMessage({
      shouldDisplayUploadProgress: true,
      isBulkPriceUpload
    });
  }
  const { salesSyncInProgress, salesSyncProgress } = useSalesSyncStatusBroadcastChannel();

  const handleSyncRetry = () => {
    closeNotificationDrawer();
    //navigate to pos sales history page or if in pos sales history reload
    if (window.location.pathname === '/pos/sales') {
      window.location.reload();
    } else {
      history.push('/pos/sales');
    }
  };

  if (!showCard) return null;
  if (type === SALES_SYNC_PARTIAL) {
    return (
      <section id={id}>
        <div className={classes.container}>
          <div className={classes.midSection}>
            <p className={classes.salesDownloadTitle} style={{ marginTop: '1rem', marginBottom: 0 }}>
              <Text i18nKey="common.inprogress.salesSync.partialSyncTitle"></Text>
            </p>
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                  marginTop: '0.5rem'
                }}
              >
                <p className={classes.salesSubTitle}>
                  <Text i18nKey="common.inprogress.salesSync.partialSyncSubtitle"></Text>
                </p>
              </div>
              <div>
                <button
                  style={{
                    textDecoration: 'underline',
                    color: '#587cc8',
                    paddingTop: 2,
                    fontSize: 14,
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    padding: 0
                  }}
                  onClick={handleSyncRetry}
                >
                  <Text i18nKey="notifications.sync.retrySync"> Retry sync</Text>
                </button>
              </div>
            </Fragment>
          </div>
        </div>
        <Divider className={classes.divider} />
      </section>
    );
  }
  if (type === SALES_SYNC) {
    return (
      <section id={id}>
        <div className={classes.container}>
          <div className={classes.midSection}>
            <p className={classes.salesDownloadTitle} style={{ marginTop: '2rem' }}>
              <Text i18nKey={TEXT[type].title}>{TEXT[type].title}</Text>
            </p>
            <Fragment>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className={classes.salesSubTitle}>
                  <Text i18nKey={TEXT[type].subtitle}>{TEXT[type].subtitle}</Text>
                </p>
              </div>
            </Fragment>
            {parseFloat(salesSyncProgress) > 0 && salesSyncInProgress ? (
              <>
                <ProgressBar style={{ marginTop: '16px' }} percentage={salesSyncProgress} />
                <div className={classes.salesDownloadTitle} style={{ margin: '0.2rem 0 1.8rem 0', fontSize: 12 }}>
                  {`${Number.parseFloat(salesSyncProgress).toFixed(2)}%`}
                </div>
              </>
            ) : (
              <div style={{ padding: '1rem' }}></div>
            )}
          </div>
        </div>
        <Divider className={classes.divider} />
      </section>
    );
  }

  if (type === 'inventoryLevelDownload') {
    return (
      <section id={id}>
        {inventoryLevelDownloadLink && (
          <div className={classes.container}>
            <img src={CheckCircleIcon} className={classes.circularProgress} alt="check circle" />
            <div className={classes.midSection}>
              <p className={classes.salesDownloadTitle}>
                <Text i18nKey={TEXT[type].downloadedTitle} variables={{ resourceTitle }}>
                  {TEXT[type].downloadedTitle}
                </Text>
              </p>
              <Fragment>
                <div className={classes.downloadedWrapper}>
                  <a
                    className={classes.downloadedSubtitle}
                    onClick={() => {
                      cancelInventoryLevelDownloadRequest();
                      setOpenedNotificationDrawer('false');
                    }}
                    href={inventoryLevelDownloadLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text i18nKey={TEXT[type].downloadedSubtitle} variables={{ resourceTitle }}>
                      {TEXT[type].downloadedSubtitle}
                    </Text>
                  </a>
                </div>
              </Fragment>
            </div>
          </div>
        )}
        {inventoryLevelDownloadLinkReqIntervalId && (
          <div className={classes.container}>
            <CircularProgress className={classes.circularProgress} size={24} />
            <div className={classes.midSection}>
              <p className={classes.salesDownloadTitle}>
                <Text i18nKey={TEXT[type].title} variables={{ resourceTitle }}>
                  {TEXT[type].title}
                </Text>
              </p>
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p className={classes.salesSubTitle}>
                    <Text i18nKey={TEXT[type].subtitle} variables={{ resourceTitle }}>
                      {TEXT[type].subtitle}
                    </Text>
                  </p>
                </div>
              </Fragment>
            </div>
          </div>
        )}

        <Divider className={classes.divider} />
      </section>
    );
  }

  if (type === 'sales') {
    return (
      <section id={id} data-testid="sales-progress-row">
        {salesHistoryLink && (
          <div className={classes.container}>
            <img src={CheckCircleIcon} className={classes.circularProgress} alt="check circle" />
            <div className={classes.midSection}>
              <p className={classes.salesDownloadTitle} data-testid="sales-progress-text">
                <Text i18nKey={TEXT[type].downloadedTitle} variables={{ resourceTitle }}>
                  {TEXT[type].downloadedTitle}
                </Text>
              </p>
              <Fragment>
                <div className={classes.downloadedWrapper}>
                  <a
                    className={classes.downloadedSubtitle}
                    data-testid="sales-subtitle-link"
                    onClick={() => {
                      cancelSalesHistoryDownloadRequest();
                      setOpenedNotificationDrawer('false');
                    }}
                    href={salesHistoryLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text i18nKey={TEXT[type].downloadedSubtitle} variables={{ resourceTitle }}>
                      {TEXT[type].downloadedSubtitle}
                    </Text>
                  </a>
                </div>
              </Fragment>
            </div>
          </div>
        )}
        {salesLinkReqIntervalId && (
          <div className={classes.container}>
            <CircularProgress className={classes.circularProgress} size={24} />
            <div className={classes.midSection}>
              <p className={classes.salesDownloadTitle}>
                <Text i18nKey={TEXT[type].title} variables={{ resourceTitle }}>
                  {TEXT[type].title}
                </Text>
              </p>
              <Fragment>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p className={classes.salesSubTitle}>
                    <Text i18nKey={TEXT[type].subtitle} variables={{ resourceTitle }}>
                      {TEXT[type].subtitle}
                    </Text>
                  </p>
                </div>
              </Fragment>
            </div>
          </div>
        )}

        <Divider className={classes.divider} />
      </section>
    );
  }

  if (success) {
    return (
      <section id={id} style={clearId === id ? { display: 'none' } : {}} data-testid="in-progress-success-section">
        <div className={classes.container}>
          {iconToDisplay()}
          <div className={classes.midSection}>
            <p className={classes.subtitle}>
              {type === 'fileUploadProcessing' ? (
                <FileProcessingDisplaySuccess
                  classes={classes}
                  processingErrorCount={processingErrorCount}
                  resourceTitle={resourceTitle}
                  priceUploadErrorMessage={priceUploadErrorMessage}
                />
              ) : (
                <Text i18nKey={subtitleToDisplay()} variables={{ resourceTitle }}>
                  {subtitleToDisplay()}
                </Text>
              )}
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {ICON[type].successSmall}
              <p className={classes.progressText}>
                <Text i18nKey="completed">Completed</Text> {` ${moment().format('MMM D, YYYY hh:mm A')}`}
              </p>
            </div>
          </div>
          <button className={classes.clearText} onClick={() => clearNotification(id)}>
            Clear
          </button>
        </div>
        <Divider className={classes.divider} />
      </section>
    );
  }

  if ((progress > 0 && progress <= 99) || processingStatus) {
    return (
      <ProgressDisplay
        type={type}
        classes={classes}
        processingStatus={processingStatus}
        resourceTitle={resourceTitle}
        errorCount={errorCount}
        processingErrorCount={processingErrorCount}
        handleViewProgress={handleViewProgress}
        priceUploadErrorMessage={priceUploadErrorMessage}
        progress={progress}
        subtitleToDisplay={subtitleToDisplay}
        iconToDisplay={iconToDisplay}
      />
    );
  }
  return null;
}

const styles = theme => ({
  container: {
    height: 90,
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    marginLeft: -24,
    marginRight: -24
  },
  smallIcon: {
    width: 15,
    marginRight: 10,
    paddingTop: 10
  },
  icon: {
    marginRight: 15,
    width: 32
  },
  midSection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  progressColor: {
    backgroundColor: '#32CBE3'
  },
  greyBg: {
    backgroundColor: '#DAE0EA'
  },
  subtitle: {
    paddingBottom: 10,
    fontSize: 13,
    fontWeight: 700
  },
  progressText: {
    paddingTop: 10,
    fontSize: 12
  },
  rootStyle: {
    borderRadius: 5
  },
  errorsText: {
    color: '#8c8888',
    fontSize: 13,
    paddingTop: 5
  },
  clearText: {
    fontSize: 13,
    color: '#959595',
    textTransform: 'uppercase',
    fontWeight: 700,
    background: 'none',
    border: 'none'
  },
  circularProgress: {
    margin: theme.spacing.unit * 2,
    color: '#FDC300'
  },
  salesDownloadTitle: {
    fontSize: 13,
    fontWeight: 700
  },
  salesSubTitle: {
    paddingTop: 2,
    fontSize: 12
  },
  downloadedWrapper: {
    diisplay: 'flex',
    alignItems: 'center'
  },
  downloadedSubtitle: {
    textDecoration: 'underline',
    color: '#FDC300',
    paddingTop: 2,
    fontSize: 14,
    cursor: 'pointer'
  },
  viewProgressButton: {
    display: 'flex',
    width: 'max-content',
    color: '#587CC8',
    background: 'none',
    marginTop: 5,
    '& span': {
      fontSize: '14px',
      lineHeight: '20px',
      marginRight: '5px'
    },
    '& svg': {
      width: '10px'
    }
  },

  errorCountContainer: {
    display: 'flex',
    backgroundColor: '#FEF3F2',
    borderRadius: '16px',
    width: 'fit-content',
    mixBlendMode: 'multiply',
    paddingRight: '10px',
    paddingLeft: '4px',
    paddingBottom: '4px',
    paddingTop: '4px',
    color: '#B42318'
  },
  failedValidationError: {
    height: '24px',
    width: '44px',
    borderRadius: '16px',
    backgroundColor: '#D92D20',
    marginRight: '8px'
  },
  failedValidationErrorText: {
    padding: '3px 9px',
    height: '18px',
    width: '44px',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    textAlign: 'center'
  },
  countText: {
    color: '#B42318'
  }
});

const ProgressCard = withRouter(withStyles(styles)(ProgressRow));

InProgressDrawer.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node
};

ProgressRow.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  errorCount: PropTypes.number,
  success: PropTypes.bool,
  showCard: PropTypes.bool,
  cancelSalesHistoryDownloadRequest: PropTypes.func.isRequired
};

export { ProgressCard, InProgressDrawer };
