import React from 'react';
import PropTypes from 'prop-types';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { withStyles } from '@material-ui/core';

export function UserInfo({ classes, name, hospital }) {
  return (
    <div className={classes.container} data-testid="user-info">
      <PersonPinIcon className={classes.icon} />
      <p className={classes.name}>{name}</p>
      <p className={classes.hospital}>{hospital}</p>
    </div>
  );
}

const styles = theme => ({
  container: {
    marginLeft: '24px'
  },
  icon: {
    fill: 'red',
    height: '35.83px',
    width: '35.83px',
    backgroundColor: '#FFFFFF'
  },
  name: {
    height: '21px',
    width: '131px',
    color: '#000000',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '21px'
  },
  hospital: {
    height: '21px',
    width: '112px',
    color: '#959595',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '21px',
    marginBottom: '16px'
  }
});

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  hospital: PropTypes.string.isRequired
};

export default withStyles(styles)(UserInfo);
