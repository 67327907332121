import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'mpharma-components-library/lib/Button';
import CountrySelect from 'mpharma-components-library/lib/CountrySelect';
import { Text } from 'mpharma-i18n';

export function SwitchFacility({ handleClose, history, classes, setOpenModal, countries }) {
  const [value, setValues] = useState('GH');

  function onSwitchFacility() {
    setOpenModal({ openFormulary: false });
    history.push(`/inventory/price-list/${value}`);
  }

  function onSelect(countryCode) {
    setValues(countryCode);
  }

  return (
    <div className={classes.container} data-testid="select-country-wrapper">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p className={classes.text}>
          <Text i18nKey="common.modals.selectCountry">Select price list by country</Text>
        </p>
        <CloseIcon
          onClick={() => handleClose('openSwitch')}
          classes={{ root: classes.icon }}
          data-testid="close-icon"
        />
      </div>

      <CountrySelect
        onChange={countryCode => onSelect(countryCode)}
        style={{ marginTop: 20 }}
        defaultCountry="GH"
        countries={countries}
      />

      <div className={classes.groupButton}>
        <Button
          secondary
          onClick={handleClose}
          text={<Text i18nKey="common.cancel">Cancel</Text>}
          data-testid="close-btn"
        />
        <Button
          style={{ marginLeft: 15 }}
          onClick={onSwitchFacility}
          text={<Text i18nKey="common.modals.selectFormulary">Select formulary</Text>}
          data-testid="select-formulary-btn"
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    paddingTop: '25.50px',
    height: '250px',
    width: '680px',
    paddingLeft: '32px',
    paddingRight: '32px',
    backgroundColor: '#ffffff',
    boxShadow: '0 12px 33px 0 rgba(0, 0, 0, 0.1)'
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    fill: '#999999',
    justifyContent: 'flex-end'
  },
  switch: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '25px'
  },
  text: {
    height: '36px',

    color: 'rgba(0,0,0,0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '36px'
  },
  options: {
    position: 'fixed',
    width: 480,
    height: 300,
    paddingLeft: 12,
    zIndex: '99999',
    overflow: 'auto'
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '25px'
  }
};

export default withStyles(styles)(SwitchFacility);
