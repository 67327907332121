import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export function Breadcrumb({ classes, history, onClose, title }) {
  function goBack() {
    history.goBack();
  }
  return (
    <div style={{ display: 'flex', paddingTop: '25px' }} data-testid="breadcrumb">
      <ArrowBack data-testid="backArrow" onClick={onClose || goBack} className={classes.backIcon} />
      <h1 className={classes.header}>{title}</h1>
    </div>
  );
}

const styles = {
  header: {
    color: '#FF4F00',
    fontFamily: 'Sofia Pro',
    fontSize: '20px',
    marginLeft: '16px',
    fontWeight: 'bold',
    lineHeight: '28px'
  },
  backIcon: {
    paddingTop: '5px',
    marginLeft: '24px',
    cursor: 'pointer',
    color: '#959595'
  }
};

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func
};

export default withRouter(withStyles(styles)(Breadcrumb));
