import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Badge } from '@material-ui/core';

const NotificationBadgeIcon = ({ classes, children }) => {
  return (
    <Badge
      color="primary"
      variant="dot"
      classes={{
        colorPrimary: classes.badgeColor,
        dot: classes.badge
      }}
    >
      {children}
    </Badge>
  );
};

const styles = () => ({
  badge: {
    width: 10,
    height: 10
  },
  badgeColor: {
    backgroundColor: 'red'
  }
});

NotificationBadgeIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default withStyles(styles)(NotificationBadgeIcon);
