import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumb from './Breadcrumb';
import SettingsLink from './SettingsLink';
import { Text } from 'mpharma-i18n';

export function SettingsMenu({ classes, onClose, handleClick, links }) {
  return (
    <Fragment>
      <Breadcrumb onClose={onClose} title={<Text i18nKey="home.settings">Settings</Text>} />
      {Object.entries(links).map(([header, { menus }], i) => (
        <div key={i} data-testid="settings-menu">
          <p className={classes.subheader}>
            <Text i18nKey={header}>{header}</Text>
          </p>
          {menus.map(menu => {
            return (
              <SettingsLink
                handleClick={menu.handleClick || handleClick(menu.title)}
                key={menu.title}
                title={<Text i18nKey={menu.title}>{menu.title}</Text>}
                subTitle={<Text i18nKey={menu.subTitle}>{menu.subTitle}</Text>}
              />
            );
          })}
        </div>
      ))}
    </Fragment>
  );
}

const styles = theme => ({
  subheader: {
    marginTop: '18px',
    marginBottom: '18px',
    height: '16px',
    marginLeft: '24px',
    color: '#FF5000',
    fontFamily: 'Sofia Pro',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0.54px',
    lineHeight: '16px'
  }
});

SettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  links: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default withStyles(styles)(SettingsMenu);
