import React from 'react';
import { ListItem, ListItemIcon, Typography, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import NotificationBadgeIcon from './NotificationBadgeIcon';
import { Text } from 'mpharma-i18n';
import { FILE_UPLOADS_STATUS, PRICE_UPLOAD_PROCESSING_STATUS } from '../../helpers/constants';

const BottomLinkListItem = ({
  classes,
  nav,
  handleClick,
  handleRefCreation,
  arrowRef,
  salesHistoryLink,
  salesLinkReqIntervalId,
  openedNotificationDrawer,
  salesHistoryBroadChanId,
  salesLocalStorageHistoryObjectId,
  inventoryLevelDownloadBroadChanId,
  inventoryLevelDownloadLink,
  inventoryLevelDownloadLinkReqIntervalId,
  inventoryLevelDownloadObjectId,
  fileUploadsProgressPageSwitched,
  fileUploadsProgress
}) => (
  <ListItem
    classes={{ root: classes.listItem }}
    onClick={nav.handleClick || handleClick(nav.tooltip)}
    button
    data-testid={`bottom-link-${nav.tooltip}`}
  >
    <ListItemIcon>
      {nav.tooltip === 'home.notifications' &&
      (salesLinkReqIntervalId ||
        salesHistoryLink ||
        inventoryLevelDownloadLinkReqIntervalId ||
        inventoryLevelDownloadLink ||
        fileUploadsProgressPageSwitched()) ? (
        <SalesDownloadNotificationsTooltip
          classes={classes}
          arrowRef={arrowRef}
          nav={nav}
          salesHistoryLink={salesHistoryLink}
          salesLinkReqIntervalId={salesLinkReqIntervalId}
          openedNotificationDrawer={openedNotificationDrawer}
          salesHistoryBroadChanId={salesHistoryBroadChanId}
          salesLocalStorageHistoryObjectId={salesLocalStorageHistoryObjectId}
          inventoryLevelDownloadBroadChanId={inventoryLevelDownloadBroadChanId}
          inventoryLevelDownloadObjectId={inventoryLevelDownloadObjectId}
          inventoryLevelDownloadLink={inventoryLevelDownloadLink}
          inventoryLevelDownloadLinkReqIntervalId={inventoryLevelDownloadLinkReqIntervalId}
          fileUploadsProgress={fileUploadsProgress}
          fileUploadsProgressPageSwitched={fileUploadsProgressPageSwitched}
        />
      ) : (
        <Tooltip placement="right" title={<Text i18nKey={nav.tooltip}>{nav.tooltip}</Text>}>
          {nav.icon}
        </Tooltip>
      )}
    </ListItemIcon>
  </ListItem>
);

const SalesDownloadNotificationsTooltip = ({
  classes,
  arrowRef,
  handleRefCreation,
  nav,
  salesHistoryLink,
  salesLinkReqIntervalId,
  openedNotificationDrawer,
  salesHistoryBroadChanId,
  salesLocalStorageHistoryObjectId,
  inventoryLevelDownloadBroadChanId,
  inventoryLevelDownloadObjectId,
  inventoryLevelDownloadLinkReqIntervalId,
  inventoryLevelDownloadLink,
  fileUploadsProgress,
  fileUploadsProgressPageSwitched
}) => {
  const fileUploadStatus = (fileUploadsProgress, status) => {
    switch (status) {
      case FILE_UPLOADS_STATUS.COMPLETED:
        return (
          !openedNotificationDrawer &&
          (fileUploadsProgress.price.progress === 100 ||
            [PRICE_UPLOAD_PROCESSING_STATUS.COMPLETED, PRICE_UPLOAD_PROCESSING_STATUS.FAILED_VALIDATION].includes(
              fileUploadsProgress.price.processingStatus
            ),
          fileUploadsProgress.stock.progress === 100 ||
            fileUploadsProgress.deliveryNote.progress === 100 ||
            fileUploadsProgress.customProduct.progress === 100)
        );

      case FILE_UPLOADS_STATUS.IN_PROGRESS:
        return (
          !openedNotificationDrawer &&
          fileUploadsProgressPageSwitched() &&
          ((fileUploadsProgress.price.progress > 0 && fileUploadsProgress.price.progress <= 99) ||
            ![PRICE_UPLOAD_PROCESSING_STATUS.COMPLETED, PRICE_UPLOAD_PROCESSING_STATUS.FAILED_VALIDATION].includes(
              fileUploadsProgress.price.processingStatus
            ) ||
            (fileUploadsProgress.stock.progress > 0 && fileUploadsProgress.stock.progress <= 99) ||
            (fileUploadsProgress.deliveryNote.progress > 0 && fileUploadsProgress.deliveryNote.progress <= 99) ||
            (fileUploadsProgress.customProduct.progress > 0 && fileUploadsProgress.customProduct.progress <= 99))
        );
      case FILE_UPLOADS_STATUS.STARTED:
        return (
          !openedNotificationDrawer &&
          (fileUploadsProgress.price.pageSwitched ||
            fileUploadsProgress.deliveryNote.pageSwitched ||
            fileUploadsProgress.stock.pageSwitched ||
            fileUploadsProgress.customProduct.pageSwitched)
        );
      default:
        return false;
    }
  };
  const downloadingSalesHistory = !openedNotificationDrawer && salesLinkReqIntervalId && salesHistoryBroadChanId;
  const downloadedSalesHistory =
    !openedNotificationDrawer &&
    ((salesHistoryLink && salesHistoryBroadChanId) || (salesHistoryLink && salesLocalStorageHistoryObjectId));
  const downloadingInventoryLevel = !inventoryLevelDownloadLink && inventoryLevelDownloadBroadChanId;
  const downloadedInventoryLevel = inventoryLevelDownloadLink && inventoryLevelDownloadBroadChanId;
  const showFileUploadProcessingToolTip = fileUploadStatus(fileUploadsProgress, FILE_UPLOADS_STATUS.STARTED);

  const fileUploadsCompleted = fileUploadStatus(fileUploadsProgress, FILE_UPLOADS_STATUS.COMPLETED);

  const fileUploadsInProgress = fileUploadStatus(fileUploadsProgress, FILE_UPLOADS_STATUS.IN_PROGRESS);

  const getUploadFileProcessingTitle = () => {
    if (fileUploadsProgress.price.progress !== 0 || fileUploadsProgress.price.processingStatus) {
      return 'Price list';
    }
    if (fileUploadsProgress.deliveryNote.progress !== 0) {
      return 'Delivery Note';
    }
    if (fileUploadsProgress.stock.progress !== 0) {
      return 'Stock Count';
    }
    if (fileUploadsProgress.customProduct.progress !== 0) {
      return 'Custom Product';
    }
    return '';
  };
  const resourceTitle = getUploadFileProcessingTitle();

  return (
    <Tooltip
      placement="right-end"
      open={
        !!(
          downloadedSalesHistory ||
          downloadingSalesHistory ||
          showFileUploadProcessingToolTip ||
          downloadingInventoryLevel ||
          downloadedInventoryLevel
        )
      }
      classes={{
        popper: classes.htmlPopper,
        tooltip: classes.htmlTooltip
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef
            }
          }
        }
      }}
      title={
        <React.Fragment>
          {downloadingSalesHistory && (
            <div>
              <Typography color="inherit" className={classes.salesDownloadingTooltipTitle}>
                <Text i18nKey="home.downloadSalesHistory.title">Downloading sales history</Text>
              </Typography>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadSalesHistory.subtitle">
                  Sales history will take some few minutes to download.
                </Text>
              </div>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadSalesHistory.progressCta">
                  Click on notification icon to view download progress.
                </Text>
              </div>

              <span className={classes.arrow} ref={handleRefCreation} />
            </div>
          )}
          {downloadedSalesHistory && (
            <div>
              <Typography color="inherit" className={classes.salesDownloadedTooltipTitle}>
                <Text i18nKey="home.downloadSalesHistory.downloaded">Sales download completed</Text>
              </Typography>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadSalesHistory.downloadedDescription">
                  Your sales history is done downloading
                </Text>
              </div>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadSalesHistory.downloadedCta">
                  Click on notification icon to view completed download.
                </Text>
              </div>

              <span className={classes.arrow} ref={handleRefCreation} />
            </div>
          )}
          {downloadingInventoryLevel && (
            <div>
              <Typography color="inherit" className={classes.salesDownloadingTooltipTitle}>
                <Text i18nKey="home.downloadInventoryLevel.title">Downloading Inventory level</Text>
              </Typography>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadInventoryLevel.subtitle">
                  Sales history will take some few minutes to download.
                </Text>
              </div>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadInventorylevel.progressCta">
                  Click on notification icon to view download progress.
                </Text>
              </div>

              <span className={classes.arrow} ref={handleRefCreation} />
            </div>
          )}
          {downloadedInventoryLevel && (
            <div>
              <Typography color="inherit" className={classes.salesDownloadedTooltipTitle}>
                <Text i18nKey="home.downloadInventoryLevel.downloaded">Inventory level download completed</Text>
              </Typography>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadInventoryLevel.downloadedDescription">
                  Your Inventory level is done downloading
                </Text>
              </div>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="home.downloadInventoryLevel.downloadedCta">
                  Click on notification icon to view completed download.
                </Text>
              </div>
            </div>
          )}
          {fileUploadsInProgress && (
            <div>
              <Typography color="inherit" className={classes.salesDownloadingTooltipTitle}>
                <Text i18nKey="common.inprogress.fileUploadProcessing.title" variables={{ resourceTitle }}>
                  <span>Your {resourceTitle} is processing</span>
                </Text>
              </Typography>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="common.inprogress.fileUploadProcessing.subtitle" variables={{ resourceTitle }}>
                  <span>Your {resourceTitle} is processing</span>
                </Text>
              </div>
              <div className={classes.salesDownloadTooltipText}>
                <Text i18nKey="common.inprogress.fileUploadProcessing.viewProgressText">
                  Click on notification icon to view processing progress.
                </Text>
              </div>
              <span className={classes.arrow} ref={handleRefCreation} />
            </div>
          )}
          {fileUploadsCompleted && (
            <div>
              <Typography color="inherit" className={classes.salesDownloadedTooltipTitle}>
                <Text i18nKey="common.inprogress.fileUploadProcessing.success" variables={{ resourceTitle }}>
                  Success
                </Text>
              </Typography>
              <span className={classes.arrow} ref={handleRefCreation} />
            </div>
          )}
        </React.Fragment>
      }
    >
      <NotificationBadgeIcon>{nav.icon}</NotificationBadgeIcon>
    </Tooltip>
  );
};

BottomLinkListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  nav: PropTypes.object.isRequired,
  salesLinkReqIntervalId: PropTypes.number,
  salesHistoryLink: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  handleRefCreation: PropTypes.func.isRequired,
  arrowRef: PropTypes.object
};

export default BottomLinkListItem;
