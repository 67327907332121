import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SettingIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import UserInfo from './UserInfo';
import { Divider, MenuItem } from '@material-ui/core';
import cornerImage from '../../images/corner.png';
import { Text } from 'mpharma-i18n';

export function MobileAccountDialog({ user, logOut, classes, handleClick, facilityName = '' }) {
  const { shutdown } = useIntercom();

  function handleLogOut() {
    shutdown();
    logOut();
  }

  return (
    <div className={classes.container} data-testid="mobile-account-dialog">
      <UserInfo name={user.first_name} hospital={facilityName} />
      <Divider />
      <div className={classes.links}>
        <SettingIcon className={classes.icon} />
        <MenuItem onClick={handleClick('home.settings')} className={classes.linkText}>
          <Text i18nKey="home.settings">Settings</Text>
        </MenuItem>
      </div>
      <div className={classes.links}>
        <LogoutIcon className={classes.icon} />
        <MenuItem onClick={handleLogOut} className={classes.linkText} data-testid="logout-btn">
          <Text i18nKey="logout">Logout</Text>
        </MenuItem>
      </div>
      <div className={classes.imageStyle}>
        <img width={'40%'} src={cornerImage} alt="mpharma-tagline" />
      </div>
    </div>
  );
}

MobileAccountDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  facilityName: PropTypes.string
};

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    marginTop: '30%'
  },

  imageStyle: {
    position: 'fixed',
    bottom: '0px',
    height: '30%',
    left: '0'
  },
  links: {
    display: 'flex',
    marginLeft: '18px',
    marginBottom: '7px',
    marginTop: '9px'
  },
  icon: {
    height: '20px',
    width: '20px',
    fill: '#FF5100'
  },
  linkText: {
    height: '21px',
    boxSizing: 'inherit',
    color: '#000000',
    marginLeft: '5px',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '21px'
  }
});

export default withStyles(styles)(MobileAccountDialog);
