import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export function SettingLink({ classes, title, subTitle, handleClick }) {
  return (
    <div className={classes.container} onClick={handleClick} data-testid="settings-link">
      <p className={classes.root}>{title}</p>
      {subTitle && <p className={classes.subTitle}>{subTitle}</p>}
    </div>
  );
}

const styles = theme => ({
  root: {
    height: '20px',
    cursor: 'pointer',
    color: 'rgba(0,0,0,0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.71px',
    padding: '0px',
    lineHeight: '20px'
  },
  subTitle: {
    width: '100%',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Sofia Pro',
    fontSize: '14px',
    letterSpacing: '0.63px',
    lineHeight: '19px'
  },
  container: {
    paddingLeft: '24px',
    paddingBottom: '10px',
    paddingTop: '10px',
    width: '415px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#eaeaea'
    }
  }
});

export default withStyles(styles)(SettingLink);
