import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#FDC302',
      contrastText: '#fff'
    },
    secondary: {
      main: '#4184F3'
    },
    error: {
      main: '#F76B6B'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#4184F3'
        },
        color: 'rgba(0,0,0,0.4)',
        letterSpacing: 1.14
      }
    },
    MuiSelect: {
      root: {
        fontSize: 16
      },
      select: {
        display: 'flex',
        alignItems: 'center'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 13,
        fontFamily: 'Sofia Pro'
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 15,
        textOverflow: 'ellipsis'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 2
      },
      label: {
        fontSize: 14
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 16
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 13,
        fontFamily: 'Sofia Pro'
      }
    }
  }
});
