import React, { useState } from 'react';
import { withStyles, Dialog } from '@material-ui/core';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import Button from 'mpharma-components-library/lib/Button';
import TextInput from 'mpharma-components-library/lib/TextInput';
import { ReactComponent as LockIcon } from '../../images/Lock.svg';
import { ReactComponent as SuccessIcon } from '../../images/OfflineSuccess.svg';
import { ReactComponent as ErrorIcon } from '../../images/offline-pin-error.svg';
import { savePin } from '../../admin/api/offline';
import { useLocalStorage } from 'react-use';
import { getUser } from '../state/auth/reducers';
import { updateFacilityUser } from '../../db/utils';

const OfflinePinModal = ({ classes }) => {
  const { translateKey } = useMpharmaI18n();
  const [hidePin, toggleHidePin] = useState(true);
  const [hideConfirmPin, toggleHideConfirmPin] = useState(true);
  const [pinData, setPinData] = useState({
    pin: '',
    confirm_pin: ''
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useLocalStorage('bloom:user');

  const [open, setOpen] = useState(false);
  const [pinErrors, setPinErrors] = useState({
    pin: {
      hasError: false,
      message: null
    },
    confirm_pin: {
      hasError: false,
      message: null
    }
  });

  React.useEffect(() => {
    if (user && !user?.is_staff && !open) {
      setOpen(!user?.user_pin || !String(user?.user_pin).includes('pbkdf2'));
    }
  }, [user, open]);

  const tryAgain = () => {
    setError(false);
    setSuccess(false);
    setLoading(false);
  };

  const updateUserInfo = async () => {
    const _user = await getUser();
    await updateFacilityUser(_user.id, _user);
    setUser(_user);
  };

  const callbackSuccess = async () => {
    await updateUserInfo();
    setSuccess(true);
    setLoading(false);
  };

  const callbackError = () => {
    setError(true);
    setLoading(false);
  };

  const saveOfflinePin = () => {
    setLoading(true);
    const data = {
      user_id: user.id,
      pin: pinData.pin
    };
    savePin(data, callbackSuccess, callbackError);
  };

  const disableButton = () => pinData.pin.length !== 4 || pinData.pin !== pinData.confirm_pin || loading;
  const resetPinInputError = () => pinData.confirm_pin.length > 0 && pinData.pin !== pinData.confirm_pin;

  const handleChange = ({ target }) => {
    const updatedPinErrors = { ...pinErrors };
    if (target.value.length !== 4) {
      updatedPinErrors[`${target.name}`].hasError = true;
      updatedPinErrors[`${target.name}`].message = translateKey('home.pinErrorLength');
    } else if (!onlyNumbers(target.value)) {
      updatedPinErrors[`${target.name}`].hasError = true;
      updatedPinErrors[`${target.name}`].message = translateKey('home.offlinePinNumber');
    } else {
      updatedPinErrors[`${target.name}`].hasError = false;
      updatedPinErrors[`${target.name}`].message = null;
    }
    setPinErrors(updatedPinErrors);
    setPinData({
      ...pinData,
      [target.name]: target.value
    });
  };

  const onlyNumbers = string => /^[0-9]*$/.test(string);

  const SuccessModal = () => (
    <React.Fragment>
      <div style={{ padding: '2rem' }}>
        <div className={classes.pinImage}>
          <SuccessIcon />
        </div>

        <div className={classes.header}>
          <Text i18nKey="home.offlinePinSet">Your Offline PIN has been set</Text>
        </div>
        <div className={classes.desc}>
          <Text i18nKey="home.offlinePinSetDescription">
            Use this PIN to login to your account anytime you don’t have internet access.
          </Text>
        </div>

        <div
          style={{
            display: 'grid',
            width: '100%',
            placeContent: 'center',
            marginTop: '1.2rem'
          }}
        >
          <Button
            data-testid="offlineContinueToBloom"
            testId="offlineContinueToBloom"
            onClick={() => {
              setOpen(false);
            }}
            style={{
              width: '348px',
              borderRadius: '6px',
              height: '48px',
              backgroundColor: '#ECB600',
              border: 'none',
              outline: 'none'
            }}
            primary
            text={
              <p
                style={{
                  height: '24px',
                  fontFamily: 'Sofia Pro',
                  fontStyle: 'normal',
                  fontWeight: '800',
                  fontSize: '13px',
                  lineHeight: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  color: '#FFFFFF'
                }}
              >
                <Text i18nKey="home.continueToBloom">Continue to Bloom</Text>
              </p>
            }
          />
        </div>
      </div>
    </React.Fragment>
  );

  const ErrorModal = () => (
    <React.Fragment>
      <div style={{ padding: '2rem' }}>
        <div className={classes.pinImage}>
          <div
            style={{
              width: '76px',
              height: '76px',
              borderRadius: '100%',
              background: '#FAEBEB',
              display: 'grid',
              placeContent: 'center'
            }}
          >
            <ErrorIcon />
          </div>
        </div>

        <div className={classes.header}>
          <Text i18nKey="home.offlineErrorHeader">Sorry, Offline PIN was not set</Text>
        </div>
        <div className={classes.desc}>
          <Text i18nKey="home.offlineErrorDescription">
            Something happened, and we are looking into. Your Offline PIN could not be set.
          </Text>
        </div>

        <div
          style={{
            display: 'grid',
            width: '100%',
            placeContent: 'center',
            marginTop: '1.6rem'
          }}
        >
          <Button
            data-testid="offlineContinueToBloom"
            testId="offlineContinueToBloom"
            onClick={async () => {
              setOpen(false);
            }}
            style={{
              width: '348px',
              borderRadius: '6px',
              height: '48px',
              backgroundColor: '#ECB600',
              border: 'none'
            }}
            primary
            text={
              <p
                style={{
                  height: '24px',
                  fontFamily: 'Sofia Pro',
                  fontStyle: 'normal',
                  fontWeight: '800',
                  fontSize: '13px',
                  lineHeight: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  color: '#FFFFFF'
                }}
              >
                <Text i18nKey="home.continueToBloom">Continue to Bloom</Text>
              </p>
            }
          />
          <button
            data-testid="offlineTryAgain"
            testId="offlineTryAgain"
            onClick={() => tryAgain()}
            style={{
              margin: '1rem auto',
              fontFamily: 'Sofia Pro',
              fontStyle: 'normal',
              fontWeight: '800',
              fontSize: '13px',
              lineHeight: '24px',
              display: 'flex',
              alignItems: 'center',
              color: '#384853',
              border: 'none',
              background: 'none',
              outline: 'none'
            }}
          >
            <Text i18nKey="error.tryAgain">Try again</Text>
          </button>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      open={open}
      aria-labelledby="offline-pin-dialog-title"
      aria-describedby="offline-pin-dialog-description"
      classes={{ paper: classes.dialogPaper }}
    >
      {!error && success && <SuccessModal />}
      {error && !success && <ErrorModal />}
      {!error && !success && (
        <div>
          <div className={classes.pinImage}>
            <div className={classes.iconBg}>
              <LockIcon />
            </div>
          </div>

          <form
            action="#"
            onSubmit={e => {
              e.preventDefault();
              saveOfflinePin();
            }}
            style={{ padding: '24px 16px' }}
          >
            <div className={classes.header} id={'offline-pin-dialog-title'}>
              <Text i18nKey="home.offlinePinModalHeader">Set a 4 digit Offline PIN</Text>
            </div>
            <div className={classes.desc} id={'offline-pin-dialog-description'}>
              <Text i18nKey="home.offlinePinModalDesc">
                Setting an Offline PIN will allow you to access Bloom when you don't have internet access.
              </Text>
            </div>

            <div style={{ marginTop: '16px' }}>
              <TextInput
                style={{
                  border: `1px solid ${pinErrors.pin.hasError ? '#EB5757' : '#E3E6EA'}`,
                  color: '#384853',
                  marginTop: '2px',
                  height: '40px',
                  backgroundColor: pinErrors.pin.hasError ? 'rgba(235, 87, 87, 0.1)' : '#FFFFFF',
                  borderRadius: '6px',
                  width: '100%',
                  outline: 'none',
                  paddingLeft: '12px'
                }}
                data-testid="offlinePin"
                label={
                  <span style={{ color: '#384853', lineHeight: '20px', fontWeight: '400' }}>
                    {translateKey('home.enterPin')}
                  </span>
                }
                name="pin"
                type="password"
                error={pinErrors.pin.hasError}
                errorMessage={<span style={{ color: '#EB5757' }}>{pinErrors.pin.message}</span>}
                placeholder={'eg. 0000'}
                fullWidth
                onChange={e => handleChange(e)}
                testId={'offlinePin'}
                value={pinData.pin}
                hidden={hidePin}
                toggleVisibility={() => toggleHidePin(!hidePin)}
              />
              <TextInput
                style={{
                  border: `1px solid ${resetPinInputError() ? '#EB5757' : '#E3E6EA'}`,
                  color: '#384853',
                  marginTop: '2px',
                  height: '40px',
                  backgroundColor: resetPinInputError() ? 'rgba(235, 87, 87, 0.1)' : '#FFFFFF',
                  borderRadius: '6px',
                  width: '100%',
                  outline: 'none',
                  paddingLeft: '12px'
                }}
                data-testid="offlineRepeat"
                label={
                  <span style={{ color: '#384853', lineHeight: '20px', fontWeight: '400' }}>
                    {translateKey('home.repeatPin')}
                  </span>
                }
                name="confirm_pin"
                placeholder={'eg. 0000'}
                fullWidth
                error={resetPinInputError()}
                errorMessage={<span style={{ color: '#EB5757' }}>{translateKey('home.pinMismatch')}</span>}
                onChange={e => handleChange(e)}
                value={pinData.confirm_pin}
                hidden={hideConfirmPin}
                testId={'offlineRepeat'}
                type="password"
                toggleVisibility={() => toggleHideConfirmPin(!hideConfirmPin)}
              />
            </div>

            <div
              style={{
                display: 'grid',
                width: '100%',
                placeContent: 'center'
              }}
            >
              <Button
                data-testid="offlineSubmitBtn"
                testId="offlineSubmitBtn"
                style={{
                  width: '350px',
                  borderRadius: '6px',
                  height: '48px',
                  backgroundColor: disableButton() ? '#DEE2E4' : '#ECB600',
                  border: 'none',
                  outline: 'none'
                }}
                disabled={disableButton()}
                type={'submit'}
                primary
                text={
                  <p
                    style={{
                      height: '24px',
                      fontFamily: 'Sofia Pro',
                      fontStyle: 'normal',
                      fontWeight: '800',
                      fontSize: '13px',
                      lineHeight: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      color: disableButton() ? '#6B7B8A' : '#FFFFFF'
                    }}
                  >
                    <Text i18nKey="home.setOfflinePin">Set Offline PIN</Text>
                  </p>
                }
              />
            </div>
          </form>
        </div>
      )}
    </Dialog>
  );
};

const styles = theme => ({
  dialogPaper: {
    padding: '24px',
    width: '432px',
    height: 'fit-content',
    borderadius: '0px'
  },
  pinImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    width: '100%'
  },
  iconBg: {
    height: '100px',
    width: '100px',
    borderRadius: '100%',
    backgroundColor: 'rgb(249,250,251)',
    display: 'grid',
    placeContent: 'center',
    placeItems: 'center'
  },
  header: {
    width: '100%',
    color: '#262626',
    fontFamily: 'Sofia Pro',
    fontSize: '24px',
    fontWeight: '800',
    letterSpacing: 0,
    lineHeight: '34px',
    textAlign: 'center'
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
      marginBottom: '10%',
      height: '100%'
    },
    fontFamily: 'Sofia Pro',
    marginTop: '20px',
    marginBottom: '10%',
    boxShadow: '0 0 6px 0 rgba(0,0,0,0.08)'
  },
  desc: {
    height: 'fit-content',
    width: 'fit-content',
    color: '#262626',
    fontFamily: 'Sofia Pro',
    fontSize: '14px',
    letterSpacing: '0',
    marginTop: '16px',
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: '20px'
  },
  body: {
    fontFamily: 'Sofia Pro',
    fontSize: '13px'
  }
});

export default withStyles(styles)(OfflinePinModal);
