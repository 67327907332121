import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { renderAppLinks } from '../../helpers/utils';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import { useMixpanel } from './Mixpanel/Provider';

export function MyBottomNavigation({ classes, user, history }) {
  const mixpanel = useMixpanel();
  const { translateKey } = useMpharmaI18n();
  return (
    <Paper className={classNames(classes.container, classes.noPrint)}>
      <BottomNavigation showLabels={true}>
        {renderAppLinks(user, history).map(({ Icon, handleClick, tooltip }, index) => (
          <BottomNavigationAction
            key={index}
            value={<Text i18nKey={tooltip} />}
            classes={{ root: classes.root, selected: classes.selected }}
            icon={<Icon />}
            onClick={() => {
              mixpanel.track('Click Bloom App', { app_name: translateKey(tooltip, { lng: 'en' }) });
              handleClick();
            }}
            data-testid={`bottom-navigation-${tooltip}`}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}

const styles = theme => ({
  root: {
    color: theme.palette.primary.main
  },
  container: {
    position: 'fixed',
    left: '0px',
    bottom: '0',
    padding: '0px',
    margin: '0px',
    height: '50px',
    width: '100%',
    zIndex: 2000
  },
  selected: {
    color: 'red'
  },
  '@media print': {
    noPrint: {
      display: 'none !important'
    }
  }
});

BottomNavigation.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(withStyles(styles)(MyBottomNavigation));
