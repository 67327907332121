import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SettingsLink from './SettingsLink';

import Breadcrumb from './Breadcrumb';
import { Text } from 'mpharma-i18n';

function AccountSettingDialog({ classes, onClose, handleClick, links }) {
  return (
    <div className={classes.container} data-testid="account-settings-dialog">
      <Breadcrumb onClose={onClose} title="Account" />
      {links.map((link, i) => (
        <SettingsLink
          key={i}
          handleClick={() => handleClick(link.link)}
          title={<Text i18nKey={link.title}>{link.title}</Text>}
        />
      ))}
    </div>
  );
}

const styles = theme => ({
  container: {
    height: '1024px',
    width: '240px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '3%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)'
  }
});

AccountSettingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  onClose: PropTypes.func
};

export default withStyles(styles)(AccountSettingDialog);
